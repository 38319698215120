import axios from 'axios';
import Vue from 'vue/dist/vue.js';

const config = Object.assign({}, window.erpPortalApiConfig);

if (localStorage.getItem('erpPortalApiAuthToken')) {
  config.headers = config.headers || {};
  config.headers.Authorization = `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`;
}

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

let initialized = false;

const init = () => {
  const urlParams = new URLSearchParams(window.location.search);

  if (!urlParams.get('module_name') || !urlParams.get('module_view')) return;

  if (urlParams.get('module_view') == 'details' && !urlParams.get('record_id')) return;

  if (initialized) return;
  initialized = true;

  const vm = new Vue({
    el: '#comments-widget',
    template: `
      <div>
        <div style="display: flex; justify-content: flex-end;">
          <button class="comments-widget-item" id="comments-widget-show-btn" @click="handleShow">
            Comments
          </button>
        </div>

        <div v-if='show' class='comments-widget-app'>
          <h6 @click='show = false' style='margin: 10px; text-transform: uppercase; text-align: right; cursor: pointer;'>Close</h6>
          <div ref='messages' v-if='comments.length > 0' class='messages'>
            <div
              v-for='comment in comments'
              :key='comment.id'
              :class='["message", { right: comment.is_mine }]'
            >
              <h5>{{ comment.author }}</h5>
              <div v-html='comment.text'></div>
              <span style='color: #828799; font-size: 12px;'>{{ comment.modified_time }}</span>
            </div>
          </div>
          <div ref='messages' v-else class='messages'>
            <p style='margin-top: 200px; text-align: center;'>No comments</p>
          </div>

          <form class='chat-box' @submit='onSubmit($event)'>
            <input
                v-model='newMessageText'
                placeholder='Write a message'
                type='text'
            />
            <button :disabled='newMessageText === ""'>Send</button>
          </form>
        </div>
      </div>
    `,
    data: {
      show: false,
      comments: [],
      newMessageText: '',
    },
    async mounted() {
      while (true) {
        if (this.show) await this.getMessages();
        await new Promise(r => setTimeout(r, 10000));
      }
    },
    methods: {
      async handleShow() {
        if (!this.show) await this.getMessages();
        this.show = !this.show;
      },
      async getMessages() {
        try {
          const response = await _axios({
            method: 'GET',
            url: '/v1/comment/list',
            params: {
              module: urlParams.get('module_name'),
              record_id: urlParams.get('record_id'),
            }
          });
  
          this.comments = [];

          for (let comment of ((response.data && response.data.data) || [])) {
            this.comments.push({
              id: comment.id,
              is_mine: comment.is_mine,
              author: comment.creator.user_fio,
              text: comment.portal_comment,
              modified_time: new Date(comment.modified_time).toLocaleString('en-US'),
            });
          }

          await new Promise(r => setTimeout(r, 100));
          const container = this.$el.querySelector('section[data-comments-widget] .comments-widget-app .messages');
          container.scrollTop = container.scrollHeight;
        } catch (e) {

        }
      },
      async onSubmit(event) {
        event.preventDefault();

        try {
          const response = await _axios({
            method: 'POST',
            url: '/v1/comment/add',
            params: {
              module: urlParams.get('module_name'),
              record_id: urlParams.get('record_id'),
              comment: `<p> ${this.newMessageText} </p>`,
              parent_id: null
            }
          });
  
          const comment = response.data.data;

          if (comment) {
            this.comments.push({
              id: comment.id,
              is_mine: comment.is_mine,
              author: comment.creator.user_fio,
              text: comment.portal_comment,
              modified_time: new Date(comment.modified_time).toLocaleString('en-US'),
            });

            this.newMessageText = '';

            await new Promise(r => setTimeout(r, 100));
            const container = this.$el.querySelector('section[data-comments-widget] .comments-widget-app .messages');
            container.scrollTop = container.scrollHeight;
          }
        } catch (e) {

        }
      },
    }
  });
};

export default () => {
  apos.util.widgetPlayers.comments = {
    selector: '[data-comments-widget]',
    player: function (el) {
      if (apos.adminBar) {
        const button = document.getElementById('comments-widget-show-btn');
        button.onclick = e => {
          GnAdditionals.Notification({
            title: 'Disable admin mode to activate this widget',
            type: 'error'
          });
        };
        button.disabled = false;
        return;
      }

      init();
    }
  };
};