import axios from 'axios';

const config = Object.assign({}, window.erpPortalApiConfig);

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return Promise.reject(error);
  }
);

let initialized = false;

const init = async () => {
  if (initialized) return;
  initialized = true;

  if (localStorage.getItem('erpPortalApiAuthToken')) {
    try {
      const response = await _axios({
        method: 'GET',
        url: '/v1/user/info',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`
        }
      });

      if (response.status == 200) {
        const url = localStorage.getItem('erpPortalApiAuthReturnUrl');
        localStorage.removeItem('erpPortalApiAuthReturnUrl');

        GnAdditionals.Notification({
          title: 'Already signed in. Redirecting...',
          type: 'success'
        });

        setTimeout(() => window.location.href = url || '/', 3000);
      }
    } catch (error) {
      localStorage.removeItem('erpPortalApiAuthToken');
    }
  }

  const form = document.getElementById('signup-widget-form');

  form.addEventListener('submit', async e => {
    e.preventDefault();

    const email = form.elements['email'].value;
    const company_name = form.elements['company_name'].value;
    const first_name = form.elements['first_name'].value;
    const last_name = form.elements['last_name'].value;
    const button = document.getElementById('signup-widget-btn');
    const loading = document.querySelector('.login-page .loading');
    button.disabled = true;
    loading.style.display = 'block';

    try {
      const response = await _axios.post('/v1/auth/signup', {
        cid: window.erpPortalApiCompany,
        email,
        company_name,
        first_name,
        last_name
      });

      if (response.data && response.data.data && response.data.data.status) {
        GnAdditionals.Notification({
          title: 'Your request has been accepted, you will receive an email with your credentials shortly. Redirecting...',
          type: 'success'
        });

        setTimeout(() => window.location.href = '/signin', 3000);
      } else {
        const title = response && response.data && response.data.data && response.data.data.message;
        throw { title };
      }
    } catch (e) {
      const title = e && e.title;
      GnAdditionals.Notification({
        title: title || 'Something went wrong please try again',
        type: 'error'
      });
      button.disabled = false;
      loading.style.display = 'none';
    }
  });
};

export default () => {
  apos.util.widgetPlayers.signup = {
    selector: '[data-signup-widget]',
    player: function (el) {
      if (apos.adminBar) {
        const form = document.getElementById('signup-widget-form');
        form.addEventListener('submit', e => {
          e.preventDefault();
          GnAdditionals.Notification({
            title: 'Disable admin mode to activate this widget',
            type: 'error'
          });
        });
        return;
      }

      init();
    }
  };
};
