import axios from 'axios';
import Vue from 'vue/dist/vue.js';

const config = Object.assign({}, window.erpPortalApiConfig);

if (localStorage.getItem('erpPortalApiAuthToken')) {
  config.headers = config.headers || {};
  config.headers.Authorization = `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`;
}

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

let initialized = false;

const init = () => {
  const urlParams = new URLSearchParams(window.location.search);

  if (!urlParams.get('module_name') || !urlParams.get('module_view')) {
    return;
  }

  if (urlParams.get('module_view') == 'details' && !urlParams.get('record_id')) {
    return;
  }

  if (initialized) {
    return;
  }
  initialized = true;

  const vm = new Vue({
    el: '#documents-widget',
    template: `
      <div class="documents-widget-block">
        <div style="display: flex; justify-content: flex-end;">
          <button class="documents-widget-btn" id="documents-widget-show-btn" @click="handleShow">
            <span v-if="documents.length" class="documents-widget-count">{{documents.length}}</span>
            <img src="/images/icon-document.svg" alt="">
          </button>
        </div>

        <div v-if='show' class='documents-widget-app' v-loading="loading">
            <span class="header-title">Documents</span>
            <div v-if="documents.length > 0" class="documents-container">
                <div v-for="(document, i) in documents" :key="i">
                    <h5>{{ document.filename }}</h5>
                    <h6>
                        Folder:
                        {{
                        (document.folder && document.folder.folder_name) || "No Folder"
                        }}
                        <br />
                        Assigned To:
                        {{
                        (document.assigned_to && document.assigned_to.assigned_name) ||
                        "Not Assigned"
                        }}
                    </h6>
                    <div>
                        <div class="document-details">
                            <a class="document-action-button"
                                    :href="document.src"
                                    target="_blank">
                                Download
                            </a>
                        </div>
                    </div>
                    <div v-if="i != documents.length - 1"
                         class="document-details-delimiter">
                    </div>
                </div>
            </div>
            <div v-else class="documents-container">
                <p style="margin-top: 200px; text-align: center">No documents</p>
            </div>
            <div class="documents-container-upload-box">
                <input type="file"
                        id="documents-container-file-input"
                        style="display: none"
                        @change="handleInputChange"/>
                <button class="document-action-button" @click="handleUpload">Upload</button>
            </div>
        </div>
      </div>
    `,
    data: {
      show: false,
      loading: false,
      documents: [],
      newMessageText: ''
    },
    created() {
      this.getDocuments();
    },
    methods: {
      handleShow() {
        if (!this.show) {
          this.getDocuments();
        }
        this.show = !this.show;
      },
      async getDocuments() {
        try {
          const response = await _axios({
            method: 'GET',
            url: '/v1/document/relation/list',
            params: {
              module: 'SO',
              record_id: urlParams.get('record_id')
            }
          });

          this.documents = response.data.data;

          await new Promise(r => setTimeout(r, 100));
        } catch (e) {

        }
      },
      handleUpload() {
        document.getElementById('documents-container-file-input').click();
      },
      async handleInputChange(event) {
        const file = event.target.files[0];

        if (!file) {
          return;
        }

        const formData = new FormData();

        formData.append('record_id', urlParams.get('record_id'));
        formData.append('module', 'SO');
        formData.append('file', file);


        this.loading = true;
        const response = await _axios({
          method: 'POST',
          url: '/v1/document/add',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: formData
        });


        if (response.data.res) {
          const document = response.data.data;

          const newDocuments = JSON.parse(
            JSON.stringify(this.documents)
          );

          newDocuments.push(document);

          this.documents = newDocuments;
          event.target.value = null;
          this.loading = false;
          await new Promise((r) => setTimeout(r, 100));
        }
      }
    }
  });
};

export default () => {
  apos.util.widgetPlayers.comments = {
    selector: '[data-detail-documents-widget]',
    player: function (el) {
      if (apos.adminBar) {
        const button = document.getElementById('detail-documents-widget-show-btn');
        button.onclick = e => {
          GnAdditionals.Notification({
            title: 'Disable admin mode to activate this widget',
            type: 'error'
          });
        };
        button.disabled = false;
        return;
      }

      init();
    }
  };
};
