import axios from "axios";

const config = Object.assign({}, window.erpPortalApiConfig);
if (localStorage.getItem('erpPortalApiAuthToken')) {
  config.headers = config.headers || {};
  config.headers.Authorization = `Bearer ${localStorage.getItem('erpPortalApiAuthToken')}`;
}
const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status == 401) {
      localStorage.removeItem('erpPortalApiAuthToken');
      localStorage.setItem('erpPortalApiAuthReturnUrl', window.location.href);
      setTimeout(() => window.location.href = '/signin', 3000);
      error.response.data.error = 'Unauthorized. Redirecting...';
    }

    return Promise.reject(error);
  }
);

import Vue from 'vue/dist/vue.js';
import { Select, Option, Input, Button, Popover } from 'element-ui';

export default () => {

  // conditions for show blocks auth & not auth users
  const isAuthUser = localStorage.getItem('erpPortalApiAuthToken');

  const blocksForNotAuthUsers = document.getElementsByClassName('for-not-auth');
  const blocksForAuthUsers = document.getElementsByClassName('for-auth');
  if (isAuthUser) {
    for (let i = 0; i < blocksForNotAuthUsers.length; i++) {
      blocksForNotAuthUsers[i].style.visibility = 'hidden';
    }

    for (let i = 0; i < blocksForAuthUsers.length; i++) {
      blocksForAuthUsers[i].style.visibility = 'inherit';
    }

    // for count shopping cart
    window.countShoppingCart = function() {
      _axios({
        method: 'GET',
        url: '/v1/quote/details'
      })
        .then(response => {
          document.getElementsByClassName('erp-shopping-cart--count')[0].innerHTML = response.data.data.partsList.length;
        })
        .catch((error) => {
          console.log(error);
        });
    };
    window.countShoppingCart();
  }

  const ShoppingCartCount = Vue.component('shopping-cart-count', {
    template: `
        <div class="erp-shopping-cart--count"></div>
    `,
    data: function () {
      return {};
    }
  });
  new Vue(ShoppingCartCount).$mount('#shopping-cart-count');

};
